<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-col cols="auto">
        Reprint Receipt
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-spacer />
      <v-col
        cols="12"
        md="4">
        <search-box
          v-model="billId"
          @on-search="fetchBills()" />
      </v-col>
    </v-row>
    <v-row class="head-search ma-0">
      <v-col
        cols="12"
        md="4"
        offset-md="4">
        <v-simple-table
          v-if="bill.billId"
          class="table is-bordered mg-auto f-s-14px">
          <tbody>
            <tr>
              <th>Bill ID</th> <td>{{ bill.billId }}</td>
            </tr>
            <tr>
              <th>สาขา</th> <td>{{ bill.branchPos.name }}</td>
            </tr>
            <tr>
              <th>POS ID</th> <td>{{ bill.branchPos.posId }}</td>
            </tr>
            <tr>
              <th>ช่องทาง</th> <td class="t-al-right">
                {{ bill.payment.name }}
              </td>
            </tr>
            <tr>
              <th>ชำระ</th> <td class="t-al-right">
                {{ bill.payment.amount | showFullPriceFormat() }}
              </td>
            </tr>
            <tr>
              <th>เงินทอน</th> <td class="t-al-right">
                {{ bill.payment.change | showFullPriceFormat() }}
              </td>
            </tr>
            <tr>
              <th>เวลาออกบิล</th> <td>{{ bill.displayTime }}</td>
            </tr>
            <tr>
              <th>แคชเชียร์</th> <td>{{ bill.cashier.firstName }} {{ bill.cashier.lastName }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="print-btn">
                  <v-btn
                    class="button w-100pct is-info my-2"
                    @click="modalPrinter = true">
                    พิมพ์ใบเสร็จ
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <modalSelectPrinter
      v-model="modalPrinter"
      :order="bill"
      :branch="bill.branchPos" />
  </v-container>
</template>

<script>
import ReceiptProvider from '@/resources/ReceiptProvider'
import SearchBox from '@/components/SearchBox.vue'
import ModalSelectPrinterVue from '../components/ModalSelectPrinter.vue'

const ReceiptService = new ReceiptProvider()
export default {
  name: 'ReprintReceipt',
  components: {
    modalSelectPrinter: ModalSelectPrinterVue,
    searchBox: SearchBox
  },
  data () {
    return {
      billId: '',
      modalPrinter: false,
      bill: {}
    }
  },
  methods: {
    async fetchBills () {
      try {
        this.$store.dispatch('Components/setLoading', {
          active: true,
          clickAble: false,
          message: 'GETTING BILL...'
        })

        const { data } = await ReceiptService.getPrintBill(this.billId)
        this.bill = data
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[GET-BILL-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.$store.dispatch('Components/setLoading', {
          active: false
        })
      }
    }
  }
}
</script>

<style scoped>
.print-btn {
  display: flex;
  justify-content: center;
}
</style>
