<template>
  <v-dialog
    :value="value"
    persistent
    max-width="600px"
    @input="setValue($event)">
    <v-card>
      <v-card-title>
        <span class="headline">Please Select Printer</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              {{ branch ? branch.name : '' }}
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedPrinter"
                :items="printerList"
                :loading="getting"
                :menu-props="{ offsetY: true }"
                :disabled="isEnablePrinterList"
                label="IP Printer"
                item-text="ip"
                outlined
                hide-details
                return-object
                @change="setPrinter($event)" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isEnableSave"
          color="blue darken-1"
          text
          @click="print()">
          Print
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="setValue(false)">
          Cancel
        </v-btn>
      </v-card-actions>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import PrinterProvider from '@/resources/PrinterProvider'

const PrinterService = new PrinterProvider()

export default {
  props: {
    branch: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      overlay: false,
      getting: false,
      printerList: [],
      selectedPrinter: {}
    }
  },
  computed: {
    isEnablePrinterList () {
      return this.printerList.length === 0
    },
    isEnableSave () {
      return this.selectedPrinter && this.selectedPrinter?.id
    }
  },
  watch: {
    value () {
      this.getPrinter()
    }
  },
  methods: {
    async getPrinter () {
      try {
        this.getting = true
        const { data } = await PrinterService.getPrinter(this.branch.branchId)
        this.printerList = data
      } catch (error) {
        console.error('getPrinter', error)
      } finally {
        this.getting = false
      }
    },
    async print () {
      try {
        this.getting = true
        const print = {
          ip: this.selectedPrinter.ip,
          status: 'pending'
        }
        let amount = 0
        let type = ''
        let name = ''

        if (this.order.payment.receivedCredit || this.order.payment.receivedCash) {
          name = this.order.payment.receivedCredit === 0 ? 'cash' : 'credit'
          type = this.order.payment.receivedCredit === 0 ? 'cash' : 'credit'
          amount = this.order.payment.receivedCredit === 0 ? this.order.payment.receivedCash : this.order.payment.receivedCredit
        } else {
          // eslint-disable-next-line prefer-destructuring
          name = this.order.payment.name
          // eslint-disable-next-line prefer-destructuring
          type = this.order.payment.type
          // eslint-disable-next-line prefer-destructuring
          amount = this.order.payment.amount
        }

        const { data } = await PrinterService.printReceipt({
          ...this.order,
          payment: {
            name,
            type,
            change: this.order.payment.change,
            amount
          },
          branchPos: { ...this.order.branchPos, unitId: +this.order.branchPos.unitId },
          print
        })
        if (data) {
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Success',
            type: 'success'
          })
          this.$emit('input', false)
        }
      } catch (error) {
        console.error('getPrinter', error)
      } finally {
        this.getting = false
      }
    },
    setBranchPrinter (value) {
      this.$emit('update:branchPrinter', value)
    },
    setPrinter (value) {
      this.$emit('update:printer', value)
    },
    setPrinterList (value) {
      this.$emit('update:printerList', value)
    },
    setValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
button.btn-printer-settings.v-btn {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
</style>
