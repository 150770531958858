import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ReceiptProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_PRINTER_API)
  }

  getPrintBill (billId) {
    this.setHeader(getAuthToken())
    return this.get(`/prints/receipt/${billId}`)
  }
}

export default ReceiptProvider
